import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import ReactGa from 'react-ga';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    ReactGa.initialize('UA-128117260-1')
    ReactGa.pageview('/')

  },[])
  return (
    <Home/>
  );
}

export default App;

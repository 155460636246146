import React, {Component} from 'react';


class Home extends React.Component {
    render() {
        return(
            <div>
                  
     <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target" id="ftco-navbar">
	    <div class="container">
	      <a class="navbar-brand" href="index.html"><span>P</span>rasanjit</a>
	      <button class="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
	        <span class="oi oi-menu"></span> Menu
	      </button>

	      <div class="collapse navbar-collapse" id="ftco-nav">
	        <ul class="navbar-nav nav ml-auto">
	          <li class="nav-item"><a href="#home-section" class="nav-link"><span>Home</span></a></li>
	          <li class="nav-item"><a href="#about-section" class="nav-link"><span>About</span></a></li>
	          <li class="nav-item"><a href="#resume-section" class="nav-link"><span>Resume</span></a></li>
	          <li class="nav-item"><a href="#blog-section" class="nav-link"><span>My Blog</span></a></li>
	          <li class="nav-item"><a href="#contact-section" class="nav-link"><span>Contact</span></a></li>
	        </ul>
	      </div>
	    </div>
	  </nav>
	  <section class="hero-wrap js-fullheight">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight justify-content-center align-items-center">
          <div class="col-lg-8 col-md-6 ftco-animate d-flex align-items-center">
          	<div class="text text-center">
          		<span class="subheading">Hey! I am</span>
		  				<h1>Prasanjit Pattanaik</h1>
			  				<h2>I'm a 
								  <span
								     class="txt-rotate"
								     data-period="2000"
								     data-rotate='[ " AWS Certified.", " Cloud Developer.", "Singer."]'></span>
								</h2>
							</div>
            </div>
          </div>
        </div>
    
      <div class="mouse">
				<a href="#" class="mouse-icon">
					<div class="mouse-wheel"><span class="ion-ios-arrow-round-down"></span></div>
				</a>
			</div>
        </section>

        <section class="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
    	<div class="container">
    		<div class="row d-flex no-gutters">
    			<div class="col-md-6 col-lg-6 d-flex">
    				<div class="img-about img d-flex align-items-stretch">
    					<div class="overlay"></div>
	    				<div class="img d-flex align-self-stretch align-items-center" style={{backgroundImage: 'url("assets/images/about.jpg")'}}>
	    				</div>
    				</div>
    			</div>
    			<div class="col-md-6 col-lg-6 pl-md-5 py-5">
    				<div class="row justify-content-start pb-3">
		          <div class="col-md-12 heading-section ftco-animate">
		          	<h1 class="big">About</h1>
		            <h2 class="mb-4">About Me</h2>
		            <p>A great ambitious and confident specific, with drive to achieve and excel on given targets.</p>
		            <ul class="about-info mt-4 px-md-0 px-2">
		            	<li class="d-flex"><span>Name:</span> <span>Prasanjit Pattanaik</span></li>
		            	<li class="d-flex"><span>Date of birth:</span> <span>April 10, 1999</span></li>
		            	<li class="d-flex"><span>Address:</span> <span>Odisha, India</span></li>
		            	<li class="d-flex"><span>Zip code:</span> <span>759100</span></li>
		            	<li class="d-flex"><span>Email:</span> <span>me@prasanjitpattanaik.com</span></li>
		            	<li class="d-flex"><span>Phone: </span> <span>+91-7008218616</span></li>
		            </ul>
		          </div>
		        </div>
	          <div class="counter-wrap ftco-animate d-flex mt-md-3">
              <div class="text">
              	<p class="mb-4">
	                <span class="number" data-number="5">0</span>
	                <span>Project complete</span>
                </p>
                <p><a href="https://s3.amazonaws.com/prasanjitpattanaik.com/PrasanjitPattanaik.pdf" target="_blank" class="btn btn-primary py-3 px-3">Download CV</a></p>
              </div>
	          </div>
	        </div>
        </div>
    	</div>
        </section>

        <section class="ftco-section ftco-partner">
    	<div class="container">
    		<div class="row">
    			<div class="col-sm ftco-animate">
    				<a href="#" class="partner"><img src="assets/images/partner-1.png" class="img-fluid" alt="Colorlib Template"/></a>
    			</div>
    			<div class="col-sm ftco-animate">
    				<a href="#" class="partner"><img src="assets/images/partner-2.png" class="img-fluid" alt="Colorlib Template"/></a>
    			</div>
    			<div class="col-sm ftco-animate">
    				<a href="#" class="partner"><img src="assets/images/partner-3.png" class="img-fluid" alt="Colorlib Template"/></a>
    			</div>
    			<div class="col-sm ftco-animate">
    				<a href="#" class="partner"><img src="assets/images/partner-4.png" class="img-fluid" alt="Colorlib Template"/></a>
    			</div>
    			<div class="col-sm ftco-animate">
    				<a href="#" class="partner"><img src="assets/images/partner-5.png" class="img-fluid" alt="Colorlib Template"/></a>
    			</div>
    		</div>
    	</div>
        </section>

        <section class="ftco-section ftco-no-pb goto-here" id="resume-section">
    	<div class="container">
    		<div class="row">
    			<div class="col-md-3">
				    <nav id="navi">
					    <ul>
					      <li><a href="#page-1">Education</a></li>
					      <li><a href="#page-2">Experience</a></li>
					      <li><a href="#page-3">Skills</a></li>
					      <li><a href="#page-4">Certifications & Projects</a></li>
					    </ul>
					  </nav>
					</div>
					<div class="col-md-9">
					  <div id="page-1" class= "page one">
					  	<h2 class="heading">Education</h2>
					    <div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					<span class="date">2017-2020</span>
		    					<h2>Bachelor of Science in IT with of Cloud Technology and Information Seucrity</h2>
		    					<span class="position">Centurion University of Technology and Management</span>
	    					</div>
	    				</div>
	    				<div class="resume-wrap d-flex ftco-animate">
	    					<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					<span class="date">2020-2022</span>
		    					<h2>Master of Computer Applications</h2>
		    					<span class="position">KIIT University</span>
		    				</div>
	    				</div>
	    				
	    				
					  </div>

					  <div id="page-2" class= "page two">
					  	<h2 class="heading">Experience</h2>
					    <div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					<span class="date"></span>
		    					<h2>Cloud Developer Intern</h2>
		    					<span class="position"><a href="https://www.vitwit.com/">VITWIT</a></span>
		    				</div>
							
	    				</div>
						<div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					<span class="date"></span>
		    					<h2>Devops Intern</h2>
		    					<span class="position"><a href="https://www.saaspect.com/">SAASPECT</a></span>
		    				</div>
	    				</div>
						<div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					<span class="date"></span>
		    					<h2>Cloud Developer Intern</h2>
		    					<span class="position"><a href="https://www.brochill.com/">BROCHILL</a></span>
		    				</div>
							
	    				</div>
	    				
	    				
					  </div>
					  <div id="page-3" class= "page three">
					  	<h2 class="heading">Skills</h2>
					  	<div class="row progress-circle mb-5">
					  		<div class="col-lg-4 mb-4">
						      <div class="bg-white rounded-lg shadow p-4">
						        <h2 class="h5 font-weight-bold text-center mb-4">AWS</h2>

						        <div class="progress mx-auto" data-value='75'>
						          <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
						            <div class="h2 font-weight-bold">75<sup class="small">%</sup></div>
						          </div>
						        </div>
						        

						        
						        <div class="row text-center mt-4">
						          <div class="col-6 border-right">
						            <div class="h4 font-weight-bold mb-0">25%</div><span class="small text-gray">Last week</span>
						          </div>
						          <div class="col-6">
						            <div class="h4 font-weight-bold mb-0">50%</div><span class="small text-gray">Last month</span>
						          </div>
						        </div>
						      </div>
						    </div>

						    <div class="col-lg-4 mb-4">
						      <div class="bg-white rounded-lg shadow p-4">
						        <h2 class="h5 font-weight-bold text-center mb-4">JAVA</h2>

						        <div class="progress mx-auto" data-value='80'>
						          <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
						            <div class="h2 font-weight-bold">80<sup class="small">%</sup></div>
						          </div>
						        </div>

						        <div class="row text-center mt-4">
						          <div class="col-6 border-right">
						            <div class="h4 font-weight-bold mb-0">28%</div><span class="small text-gray">Last week</span>
						          </div>
						          <div class="col-6">
						            <div class="h4 font-weight-bold mb-0">60%</div><span class="small text-gray">Last month</span>
						          </div>
						        </div>
						      </div>
						    </div>

						    <div class="col-lg-4 mb-4">
						      <div class="bg-white rounded-lg shadow p-4">
						        <h2 class="h5 font-weight-bold text-center mb-4">C/C++</h2>

						        <div class="progress mx-auto" data-value='80'>
						          <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
						          </span>
						          <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
						            <div class="h2 font-weight-bold">80<sup class="small">%</sup></div>
						          </div>
						        </div>

						        <div class="row text-center mt-4">
						          <div class="col-6 border-right">
						            <div class="h4 font-weight-bold mb-0">28%</div><span class="small text-gray">Last week</span>
						          </div>
						          <div class="col-6">
						            <div class="h4 font-weight-bold mb-0">60%</div><span class="small text-gray">Last month</span>
						          </div>
						        </div>
						      </div>
						    </div>
					  	</div>
					  	<div class="row">
					  		<div class="col-md-6 animate-box">
									<div class="progress-wrap ftco-animate">
										<h3>Golang</h3>
										<div class="progress">
										 	<div class="progress-bar color-1" role="progressbar" aria-valuenow="90"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:"70%"}}>
										    <span>70%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 animate-box">
									<div class="progress-wrap ftco-animate">
										<h3>Linux / MAC</h3>
										<div class="progress">
										 	<div class="progress-bar color-2" role="progressbar" aria-valuenow="85"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:"85%"}}>
										    <span>85%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 animate-box">
									<div class="progress-wrap ftco-animate">
										<h3>Virtualization</h3>
										<div class="progress">
										 	<div class="progress-bar color-3" role="progressbar" aria-valuenow="95"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:"80%"}}>
										    <span>80%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 animate-box">
									<div class="progress-wrap ftco-animate">
										<h3>Networking</h3>
										<div class="progress">
										 	<div class="progress-bar color-4" role="progressbar" aria-valuenow="80"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:"80%"}}>
										    <span>80%</span>
										  	</div>
										</div>
									</div>
								</div>
								
					  	</div>
					  </div>
					  <div id="page-4" class= "page four">
					    <h2 class="heading">Certifications & Projects</h2>
					    <div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					
		    					<h2>Cloud ERP Management System</h2>
		    					<span class="position">Cloud DMS</span>
								<p>Created an ERP Management and Online Examination portal. Which was hosted on AWS Cloud for better performance and security. We named it as Cloud DMS, which was link to the domain name that is clouddms.in .</p>
								<p></p>
	    					</div>
	    				</div>
						<div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					
		    					<h2>AWS CLI for S3</h2>
		    					<span class="position">AWS Cloud</span>
								<p>Created a CLI for Linux and Mac OS system to perform S3 opertions on AWS Cloud. This CLI tool was designed using Golang and AWS Goland SDKs for S3 Operations.</p>
	    					</div>
	    				</div>
						<div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					
		    					<h2>Grow Box</h2>
		    					<span class="position">IOT</span>
								<p>Created an Agri Tech project which was based on IOT and connected over Blynk, to automate and optimize farming.</p>
	    					</div>
	    				</div>
						<div class="resume-wrap d-flex ftco-animate">
					    	<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					
		    					<h2>AWS Certified Cloud Practitioner</h2>
		    					<span class="position">AWS Cloud Certification</span>
								<p>Achived the AWS Certification as a Cloud Practitioner on 22nd Feb 2020, valid till 22nd Feb 2023.</p>
	    					</div>
	    				</div>
	    				<div class="resume-wrap d-flex ftco-animate">
	    					<div class="icon d-flex align-items-center justify-content-center">
					    		<span class="flaticon-ideas"></span>
					    	</div>
					    	<div class="text pl-3">
		    					
		    					<h2>AWS Technical Professional</h2>
		    					<span class="position">AWS Cloud Training</span>
								<p>Completed the AWS Technical Professional Training on Amazon Partner Netwrok(APN).</p>
		    				</div>
	    				</div>
	    				
					  </div>
					</div>
			  </div>
    	</div>
        </section>
    

        <section class="ftco-section" id="blog-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-5">
          <div class="col-md-7 heading-section text-center ftco-animate">
            <h1 class="big big-2">Blog</h1>
            <h2 class="mb-4">My Blogs</h2>
            <p>Have written some technical vlogs on AWS Cloud and it's usages click on the below topic to read it on Medium's website.</p>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-4 d-flex ftco-animate">
          	<div class="blog-entry justify-content-end">
              <a href="https://medium.com/@prasanjitpattanaik/ddos-dos-usecases-solutions-2f646af2e7b5" class="block-20" style={{backgroundImage: 'url("assets/images/image_1.png")'}}>
              </a>
              <div class="text mt-3 float-right d-block">
                <h3 class="heading"><a href="https://medium.com/@prasanjitpattanaik/ddos-dos-usecases-solutions-2f646af2e7b5">DOS(Denial Of Service)& DDOS(Distributed Denial Of Service) SOLUTION</a></h3>
                <div class="d-flex align-items-center mb-3 meta">
	                <p class="mb-0">
	                	<span class="mr-2">April. 18, 2020</span>
	                	<a href="https://medium.com/@prasanjitpattanaik" class="mr-2">Prasanjit Pattanaik</a>
	                </p>
                </div>
                <p>Before moving into the topic let’s get some clear knowledge about what is DOS or DDOS.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex ftco-animate">
          	<div class="blog-entry justify-content-end">
              <a href="https://medium.com/@prasanjitpattanaik/s3-usecase-solutions-a53f6e26e86f" class="block-20" style={{backgroundImage: 'url("assets/images/image_2.jpg")'}}>
              </a>
              <div class="text mt-3 float-right d-block">
                <h3 class="heading"><a href="https://medium.com/@prasanjitpattanaik/s3-usecase-solutions-a53f6e26e86f">All About AWS Identity & Access Management</a></h3>
                <div class="d-flex align-items-center mb-3 meta">
	                <p class="mb-0">
	                	<span class="mr-2">April. 03, 2020</span>
	                	<a href="https://medium.com/@prasanjitpattanaik" class="mr-2">Prasanjit Pattanaik</a>
	                </p>
                </div>
                <p>If you ever have worked with AWS cloud in a group project or in an organization then you must have come across the security service that is provided by AWS that is IAM</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex ftco-animate">
          	<div class="blog-entry">
              <a href="https://medium.com/@prasanjitpattanaik/s3-usecase-solutions-a53f6e26e86f" class="block-20" style={{backgroundImage: 'url("assets/images/image_3.jpg")'}}>
              </a>
              <div class="text mt-3 float-right d-block">
                <h3 class="heading"><a href="https://medium.com/@prasanjitpattanaik/s3-usecase-solutions-a53f6e26e86f">Got rid of storage racks, your solution to almost every storage issue is here just click to know more .</a></h3>
                <div class="d-flex align-items-center mb-3 meta">
	                <p class="mb-0">
	                	<span class="mr-2">Apr. 15, 2020</span>
	                	<a href="https://medium.com/@prasanjitpattanaik" class="mr-2">Prasanjit Pattanaik</a>
	                	
	                </p>
                </div>
                <p>Are you working with the collection of huge sets of data? Are you also struggling with your own storage racks to store a huge amount of upcoming data?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </section>

        <section class="ftco-section contact-section ftco-no-pb" id="contact-section">
      <div class="container">
      	<div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 heading-section text-center ftco-animate">
            <h1 class="big big-2">Contact</h1>
            <h2 class="mb-4">Contact Me</h2>
            <p></p>
          </div>
        </div>

        <div class="row d-flex contact-info mb-5">
          <div class="col-md-6 col-lg-3 d-flex ftco-animate">
          	<div class="align-self-stretch box text-center p-4 shadow">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-map-signs"></span>
          		</div>
          		<div>
	          		<h3 class="mb-4">Address</h3>
		            <p>Talcher, Odisha, India</p>
		          </div>
	          </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex ftco-animate">
          	<div class="align-self-stretch box text-center p-4 shadow">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-phone2"></span>
          		</div>
          		<div>
	          		<h3 class="mb-4">Contact Number</h3>
		            <p><a href="tel://7008218616">+91 700 82186 16</a></p>
	            </div>
	          </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex ftco-animate">
          	<div class="align-self-stretch box text-center p-4 shadow">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-paper-plane"></span>
          		</div>
          		<div>
	          		<h3 class="mb-4">Email Address</h3>
		            <p><a href="mailto:me@prasanjitpattanaik.com">me@prasanjitpattanaik.com</a></p>
		          </div>
	          </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex ftco-animate">
          	<div class="align-self-stretch box text-center p-4 shadow">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-globe"></span>
          		</div>
          		<div>
	          		<h3 class="mb-4">Website</h3>
		            <p><a href="https://www.prasanjitpattanaik.com/">prasanjitpattanaik.com</a></p>
	            </div>
	          </div>
          </div>
        </div>
        </div>
        </section>
		
     <footer style={{backgroundcolor: "black"}}>
		<div class="container">
        <div class="row mb-5" style={{paddingTop:"10px"}}>
          <div class="col-md">
            <div class="ftco-footer-widget mb-4">
              
              <p>Feel free to connect with me on my social media handles, for sharing suggestions, quries, Freelancing works and can Hire me! if you feel like.</p>
              <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li class="ftco-animate"><a href="https://twitter.com/pattanaikpj" target="_blank"><span class="icon-twitter"></span></a></li>
                <li class="ftco-animate"><a href="https://www.facebook.com/prasanjit.cool" target="_blank"><span class="icon-facebook"></span></a></li>
                <li class="ftco-animate"><a href="https://www.instagram.com/mr.prasanjitpattanaik" target="_blank"><span class="icon-instagram"></span></a></li>
				<li class="ftco-animate"><a href="https://in.linkedin.com/in/prasanjit-pattanaik-9b5704154" target="_blank"><span class="icon-linkedin"></span></a></li>
				<li class="ftco-animate"><a href="https://github.com/PrasanjitPattanaik" target="_blank"><span class="icon-github"></span></a></li>
				<li class="ftco-animate"><a href="https://wa.me/917008218616?text=Hello%20Prasanjit%20Pattanaik" target="_blank"><span class="icon-whatsapp"></span></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md">
            <div class="ftco-footer-widget mb-4">
            	
            	<div class="block-23 mb-3">
	              <ul>
	                 </ul>
	            </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
			<p>
			Copyright &copy;2021 All rights reserved by prasanjitpattanaik.com . SECURED BY SSL
			</p>
			<p>
			<a href="mailto:admin@prasanjitpattanaik.com">Any query related to the website can be reported at admin@prasanjitpattanaik.com</a>
			</p>
        
          </div>
        </div>
      </div>
        </footer>
           
                    <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>


                   
            </div>
        )
    }
}
export default Home 